@import url('https://fonts.googleapis.com/css?family=Electrolize&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:300,400,500,700&display=swap');

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tDMPKhSkFEkm8.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tMMPKhSkFEkm8.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tCMPKhSkFE.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucXtAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLufntAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucHtAOvWDSA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucXtAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTufntAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucHtAOvWDSA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMRrTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtM1rTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMVrTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMprTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMZrTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMdrTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMlrTFcZZJmOpw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/**
 * Do not edit directly
 * Generated on Wed, 20 Dec 2023 08:32:38 GMT
 */

:root {
  --redsift-color-redsift: #e11010;
  --redsift-color-product-asm: #8361ea;
  --redsift-color-product-brand-trust: #7ec2fa;
  --redsift-color-product-certificates: #e8712e;
  --redsift-color-product-ondmarc: #96d36e;
  --redsift-color-product-radar: #51b7a4;
  --redsift-color-product-rojo-ds: #e11010;
  --redsift-color-product-vendor-secure: #f7c948;
  --redsift-color-product-website: #e11010;
  --redsift-color-neutral-black: #1c1c1c;
  --redsift-color-neutral-darkgrey: #333333;
  --redsift-color-neutral-midgrey: #666666;
  --redsift-color-neutral-lightgrey: #b6b6b6;
  --redsift-color-neutral-xlightgrey: #f2f2f2;
  --redsift-color-neutral-white: #ffffff;
  --redsift-app-bar-color-background: #ffffff;
  --redsift-app-bar-color-text: #1c1c1c;
  --redsift-app-bar-color-expand-icon-resting: #333333;
  --redsift-app-bar-color-expand-icon-hover: #1c1c1c;
  --redsift-app-bar-color-expand-icon-background-hover: #ffffff;
  --redsift-color-ctas-primary-primary: #4285f4;
  --redsift-color-ctas-primary-hover: #d9e7fd;
  --redsift-color-ctas-primary-active: #b3cefb;
  --redsift-color-ctas-primary-active-hover: #8eb6f8;
  --redsift-color-ctas-primary-button-hover: #356ac3;
  --redsift-color-ctas-primary-button-active: #285092;
  --redsift-color-ctas-primary-button-active-hover: #1a3562;
  --redsift-color-ctas-secondary-primary: #51b7a4;
  --redsift-color-ctas-secondary-hover: #dcf1ed;
  --redsift-color-ctas-secondary-active: #b9e2db;
  --redsift-color-ctas-secondary-active-hover: #97d4c8;
  --redsift-color-ctas-secondary-button-hover: #419283;
  --redsift-color-ctas-secondary-button-active: #316e62;
  --redsift-color-ctas-secondary-button-active-hover: #204942;
  --redsift-color-notifications-success-primary: #02ac61;
  --redsift-color-notifications-success-hover: #cceedf;
  --redsift-color-notifications-success-active: #9adec0;
  --redsift-color-notifications-success-active-hover: #67cda0;
  --redsift-color-notifications-success-button-hover: #028a4e;
  --redsift-color-notifications-success-button-active: #01673a;
  --redsift-color-notifications-success-button-active-hover: #014527;
  --redsift-color-notifications-error-primary: #e11010;
  --redsift-color-notifications-error-hover: #f9cfcf;
  --redsift-color-notifications-error-active: #f39f9f;
  --redsift-color-notifications-error-active-hover: #ed7070;
  --redsift-color-notifications-error-button-hover: #b40d0d;
  --redsift-color-notifications-error-button-active: #870a0a;
  --redsift-color-notifications-error-button-active-hover: #5a0606;
  --redsift-color-notifications-warning-primary: #fcbb54;
  --redsift-color-notifications-warning-hover: #fef1dd;
  --redsift-color-notifications-warning-active: #fee4bb;
  --redsift-color-notifications-warning-active-hover: #fdd698;
  --redsift-color-notifications-warning-button-hover: #ca9643;
  --redsift-color-notifications-warning-button-active: #977032;
  --redsift-color-notifications-warning-button-active-hover: #654b22;
  --redsift-color-notifications-info-primary: #4285f4;
  --redsift-color-notifications-info-hover: #d9e7fd;
  --redsift-color-notifications-info-active: #b3cefb;
  --redsift-color-notifications-info-active-hover: #8eb6f8;
  --redsift-color-notifications-info-button-hover: #356ac3;
  --redsift-color-notifications-info-button-active: #285092;
  --redsift-color-notifications-info-button-active-hover: #1a3562;
  --redsift-color-notifications-question-primary: #666666;
  --redsift-color-notifications-question-hover: #b2b2b2;
  --redsift-color-notifications-question-active: #a3a3a3;
  --redsift-color-notifications-question-active-hover: #949494;
  --redsift-color-notifications-question-button-hover: #525252;
  --redsift-color-notifications-question-button-active: #474747;
  --redsift-color-notifications-question-button-active-hover: #3d3d3d;
  --redsift-color-notifications-no-data-primary: #bdbdbd;
  --redsift-color-notifications-no-data-hover: #dedede;
  --redsift-color-notifications-no-data-active: #d7d7d7;
  --redsift-color-notifications-no-data-active-hover: #d1d1d1;
  --redsift-color-notifications-no-data-button-hover: #979797;
  --redsift-color-notifications-no-data-button-active: #848484;
  --redsift-color-notifications-no-data-button-active-hover: #717171;
  --redsift-color-presentation-green-darkerer: #078833;
  --redsift-color-presentation-green-darker: #07aa45;
  --redsift-color-presentation-green-dark: #00ce59;
  --redsift-color-presentation-green-default: #56d58e;
  --redsift-color-presentation-green-light: #a2e7ba;
  --redsift-color-presentation-green-lighter: #c2efd1;
  --redsift-color-presentation-green-lighterer: #e1f7e8;
  --redsift-color-presentation-pink-darkerer: #b10078;
  --redsift-color-presentation-pink-darker: #c5008c;
  --redsift-color-presentation-pink-dark: #d800a2;
  --redsift-color-presentation-pink-default: #d95cba;
  --redsift-color-presentation-pink-light: #eca0d6;
  --redsift-color-presentation-pink-lighter: #f4c0e3;
  --redsift-color-presentation-pink-lighterer: #fae0f1;
  --redsift-color-presentation-aqua-darkerer: #0b9287;
  --redsift-color-presentation-aqua-darker: #0ab5ab;
  --redsift-color-presentation-aqua-dark: #00d9d2;
  --redsift-color-presentation-aqua-default: #63eae4;
  --redsift-color-presentation-aqua-light: #9af8f4;
  --redsift-color-presentation-aqua-lighter: #caf7f4;
  --redsift-color-presentation-aqua-lighterer: #e5fbfa;
  --redsift-color-presentation-brown-darkerer: #701205;
  --redsift-color-presentation-brown-darker: #903107;
  --redsift-color-presentation-brown-dark: #af5100;
  --redsift-color-presentation-brown-default: #c78348;
  --redsift-color-presentation-brown-light: #edc19c;
  --redsift-color-presentation-brown-lighter: #f6d9c3;
  --redsift-color-presentation-brown-lighterer: #fae6d7;
  --redsift-color-presentation-red-darkerer: #b10004;
  --redsift-color-presentation-red-darker: #c70003;
  --redsift-color-presentation-red-dark: #de0000;
  --redsift-color-presentation-red-default: #e06363;
  --redsift-color-presentation-red-light: #f3a39e;
  --redsift-color-presentation-red-lighter: #f8bbba;
  --redsift-color-presentation-red-lighterer: #fddddc;
  --redsift-color-presentation-yellow-darkerer: #8a7f18;
  --redsift-color-presentation-yellow-darker: #bcad14;
  --redsift-color-presentation-yellow-dark: #f0de00;
  --redsift-color-presentation-yellow-default: #fff741;
  --redsift-color-presentation-yellow-light: #f7efc3;
  --redsift-color-presentation-yellow-lighter: #fbf5db;
  --redsift-color-presentation-yellow-lighterer: #fcf9e7;
  --redsift-color-presentation-purple-darkerer: #4702c1;
  --redsift-color-presentation-purple-darker: #7902eb;
  --redsift-color-presentation-purple-dark: #9200ff;
  --redsift-color-presentation-purple-default: #965ede;
  --redsift-color-presentation-purple-light: #c6a8ef;
  --redsift-color-presentation-purple-lighter: #decaf6;
  --redsift-color-presentation-purple-lighterer: #e9dcf9;
  --redsift-color-presentation-orange-darkerer: #b45310;
  --redsift-color-presentation-orange-darker: #d2710b;
  --redsift-color-presentation-orange-dark: #ed9200;
  --redsift-color-presentation-orange-default: #fcbb54;
  --redsift-color-presentation-orange-light: #f8d296;
  --redsift-color-presentation-orange-lighter: #fde4c0;
  --redsift-color-presentation-orange-lighterer: #feedd5;
  --redsift-color-presentation-blue-darkerer: #0082f8;
  --redsift-color-presentation-blue-darker: #0099fd;
  --redsift-color-presentation-blue-dark: #00aeff;
  --redsift-color-presentation-blue-default: #73c5eb;
  --redsift-color-presentation-blue-light: #b1dcf3;
  --redsift-color-presentation-blue-lighter: #cce8f7;
  --redsift-color-presentation-blue-lighterer: #e6f3fb;
  --redsift-color-presentation-grey-darkerer: #6e6e6e;
  --redsift-color-presentation-grey-darker: #959595;
  --redsift-color-presentation-grey-dark: #bfbfbf;
  --redsift-color-presentation-grey-default: #d6d6d6;
  --redsift-color-presentation-grey-light: #e6e6e6;
  --redsift-color-presentation-grey-lighter: #eeeeee;
  --redsift-color-presentation-grey-lighterer: #f7f7f7;
  --redsift-side-navigation-color-background: #f2f2f2;
  --redsift-side-navigation-color-menu-item-text-resting: #333333;
  --redsift-side-navigation-color-menu-item-text-disabled: #666666;
  --redsift-side-navigation-color-menu-item-text-hover: #1c1c1c;
  --redsift-side-navigation-color-menu-item-background-hover: #e6e6e6;
  --redsift-side-navigation-color-menu-item-background-secondary: transparent;
  --redsift-side-navigation-color-menu-item-background-active: #b6b6b6;
  --redsift-side-navigation-color-menu-item-active: #1c1c1c;
  --redsift-side-navigation-color-scrollbar-resting: #b6b6b6;
  --redsift-side-navigation-color-scrollbar-hover: #666666;
  --redsift-layout-z-index-footer: 1000;
  --redsift-layout-z-index-header: 1010;
  --redsift-layout-z-index-side-panel: 1050;
  --redsift-layout-z-index-dropdown: 1100;
  --redsift-layout-z-index-overlay: 1150;
  --redsift-layout-z-index-dialog: 1200;
  --redsift-layout-z-index-popover: 1250;
  --redsift-layout-z-index-tooltip: 1300;
  --redsift-typography-font-family-poppins: 'Poppins', sans-serif;
  --redsift-typography-font-family-source-code-pro: 'Source Code Pro', sans-serif;
  --redsift-typography-font-weight-thin: 100;
  --redsift-typography-font-weight-extra-light: 200;
  --redsift-typography-font-weight-light: 300;
  --redsift-typography-font-weight-regular: 400;
  --redsift-typography-font-weight-medium: 500;
  --redsift-typography-font-weight-semi-bold: 600;
  --redsift-typography-font-weight-bold: 700;
  --redsift-typography-font-weight-extra-bold: 800;
  --redsift-typography-font-weight-black: 900;
  --redsift-typography-h1-font-family: 'Poppins', sans-serif;
  --redsift-typography-h1-font-size: 24px;
  --redsift-typography-h1-font-weight: 600;
  --redsift-typography-h1-line-height: 36px;
  --redsift-typography-h1-text-transform: unset;
  --redsift-typography-h2-font-family: 'Poppins', sans-serif;
  --redsift-typography-h2-font-size: 22px;
  --redsift-typography-h2-font-weight: 600;
  --redsift-typography-h2-line-height: 30px;
  --redsift-typography-h2-text-transform: unset;
  --redsift-typography-h3-font-family: 'Poppins', sans-serif;
  --redsift-typography-h3-font-size: 20px;
  --redsift-typography-h3-font-weight: 600;
  --redsift-typography-h3-line-height: 30px;
  --redsift-typography-h3-text-transform: unset;
  --redsift-typography-h4-font-family: 'Poppins', sans-serif;
  --redsift-typography-h4-font-size: 16px;
  --redsift-typography-h4-font-weight: 600;
  --redsift-typography-h4-line-height: 24px;
  --redsift-typography-h4-text-transform: unset;
  --redsift-typography-h5-font-family: 'Poppins', sans-serif;
  --redsift-typography-h5-font-size: 12px;
  --redsift-typography-h5-font-weight: 600;
  --redsift-typography-h5-line-height: 18px;
  --redsift-typography-h5-text-transform: unset;
  --redsift-typography-body-font-family: 'Poppins', sans-serif;
  --redsift-typography-body-font-size: 15px;
  --redsift-typography-body-font-weight: 400;
  --redsift-typography-body-line-height: 24px;
  --redsift-typography-body-text-transform: unset;
  --redsift-typography-link-font-family: 'Poppins', sans-serif;
  --redsift-typography-link-font-size: 15px;
  --redsift-typography-link-font-weight: 400;
  --redsift-typography-link-line-height: 26px;
  --redsift-typography-input-text-font-family: 'Poppins', sans-serif;
  --redsift-typography-input-text-font-size: 15px;
  --redsift-typography-input-text-font-weight: 400;
  --redsift-typography-input-text-line-height: 24px;
  --redsift-typography-button-font-family: 'Poppins', sans-serif;
  --redsift-typography-button-font-size: 16px;
  --redsift-typography-button-font-weight: 500;
  --redsift-typography-button-line-height: 24px;
  --redsift-typography-button-text-transform: uppercase;
  --redsift-typography-caption-font-family: 'Poppins', sans-serif;
  --redsift-typography-caption-font-size: 13px;
  --redsift-typography-caption-font-weight: 400;
  --redsift-typography-caption-line-height: 18px;
  --redsift-typography-caption-text-transform: unset;
  --redsift-typography-helper-font-family: 'Poppins', sans-serif;
  --redsift-typography-helper-font-size: 16px;
  --redsift-typography-helper-font-weight: 400;
  --redsift-typography-helper-line-height: 24px;
  --redsift-typography-helper-text-transform: unset;
  --redsift-typography-badge-font-family: 'Poppins', sans-serif;
  --redsift-typography-badge-font-size: 12px;
  --redsift-typography-badge-font-weight: 400;
  --redsift-typography-badge-line-height: 12px;
  --redsift-typography-pill-font-family: 'Poppins', sans-serif;
  --redsift-typography-pill-font-size: 10px;
  --redsift-typography-pill-font-weight: 400;
  --redsift-typography-pill-line-height: 15px;
  --redsift-typography-chip-font-family: 'Poppins', sans-serif;
  --redsift-typography-chip-font-size: 12px;
  --redsift-typography-chip-font-weight: 400;
  --redsift-typography-chip-line-height: 18px;
  --redsift-typography-tooltip-font-family: 'Poppins', sans-serif;
  --redsift-typography-tooltip-font-size: 12px;
  --redsift-typography-tooltip-font-weight: 400;
  --redsift-typography-tooltip-line-height: 18px;
  --redsift-typography-datagrid-header-font-family: 'Poppins', sans-serif;
  --redsift-typography-datagrid-header-font-size: 14px;
  --redsift-typography-datagrid-header-font-weight: 600;
  --redsift-typography-datagrid-header-line-height: 21px;
  --redsift-typography-datagrid-cell-font-family: 'Poppins', sans-serif;
  --redsift-typography-datagrid-cell-font-size: 16px;
  --redsift-typography-datagrid-cell-font-weight: 400;
  --redsift-typography-datagrid-cell-line-height: 24px;
}
/* Reseting CSS. */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* * {
  box-sizing: border-box;
} */
/* Applying Design System style to native HTML tags. */

body {
  font-family: var(--redsift-typography-body-font-family);
  font-size: var(--redsift-typography-body-font-size);
  font-weight: var(--redsift-typography-body-font-weight);
  line-height: var(--redsift-typography-body-line-height);
  margin: unset;
  color: var(--redsift-color-neutral-black);
}

h1 {
  font-family: var(--redsift-typography-h1-font-family);
  font-size: var(--redsift-typography-h1-font-size);
  font-weight: var(--redsift-typography-h1-font-weight);
  line-height: var(--redsift-typography-h1-line-height);
  color: var(--redsift-color-neutral-black);
}

h2 {
  font-family: var(--redsift-typography-h2-font-family);
  font-size: var(--redsift-typography-h2-font-size);
  font-weight: var(--redsift-typography-h2-font-weight);
  line-height: var(--redsift-typography-h2-line-height);
  color: var(--redsift-color-neutral-black);
}

h3 {
  font-family: var(--redsift-typography-h3-font-family);
  font-size: var(--redsift-typography-h3-font-size);
  font-weight: var(--redsift-typography-h3-font-weight);
  line-height: var(--redsift-typography-h3-line-height);
  color: var(--redsift-color-neutral-black);
}

h4 {
  font-family: var(--redsift-typography-h4-font-family);
  font-size: var(--redsift-typography-h4-font-size);
  font-weight: var(--redsift-typography-h4-font-weight);
  line-height: var(--redsift-typography-h4-line-height);
  color: var(--redsift-color-neutral-black);
}

a {
  background: none;
  border: none;
  color: var(--redsift-color-ctas-primary-primary);
  font-family: var(--redsift-typography-link-font-family);
  font-size: var(--redsift-typography-link-font-size);
  font-weight: var(--redsift-typography-link-font-weight);
  line-height: var(--redsift-typography-link-line-height);
  padding: unset;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--redsift-color-ctas-primary-hover);
          text-decoration-color: var(--redsift-color-ctas-primary-hover);
  text-underline-offset: 2px;
}

a:hover,
a:focus-visible {
  cursor: pointer;
  -webkit-text-decoration-color: var(--redsift-color-ctas-primary-primary);
          text-decoration-color: var(--redsift-color-ctas-primary-primary);
}

a:focus-visible {
  outline: 2px solid var(--redsift-color-ctas-primary-primary);
}

strong {
  font-weight: bold;
}

code,
pre,
kbd,
samp {
  font-family: var(--redsift-typography-font-family-source-code-pro);
}

b {
  font-weight: bold;
}

i,
cite,
em {
  font-style: italic;
}

sub,
sup {
  font-size: 12px;
}

sub {
  vertical-align: sub;
}

sup {
  vertical-align: super;
}

